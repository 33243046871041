@font-face {
  font-family: 'Bungee Inline', cursive;
  src: url('https://fonts.googleapis.com/css?family=Roboto:400,500,600');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.card {
  border-radius: 10px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
