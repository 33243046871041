.add-new-post__editor .ql-container,
.add-new-post__editor .ql-editor {
  min-height: 200px;
}

.image-gallery-upload {
  height: 50px;
  overflow: auto;
}

.image-gallery-upload-item {
  max-height: 50px;
  margin-right: 0.3rem;
}

.remove-button {
  position: absolute;
  top: 0px;
  right: 0px;
}

.box {
  height: 80px;
  width: 80px;
  display: flex;
  background-position: center;
  background-size: cover;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  line-height: normal;
  font-weight: bold;
  border-radius: 40px;
  border: 1px solid #eee;
  text-align: center;
  text-transform: uppercase;
  color: black;
}

.days-to-go { position: relative; }
.days-to-go::after {
  content: 'USD';
  font-weight: 800;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%); }
