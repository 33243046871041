.hover-opacity:hover {
  opacity: 0.4;
}

.hlTfmo { margin-bottom: 0px !important; }
.kJnpoy { border: 1px solid #DDD; padding: 7px 12px; }
label { margin: 0px };

@media screen and (min-width: 1400px) {
  .col-xxl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

.main-content-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.card-post__author-avatar {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.material-icons {
  font-size: 20px !important;
}